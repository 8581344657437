import create from 'zustand';

export const useAdvancedSearch = create((set, get) => ({
    rows: [],
    updateRows: (newRows) => set({ rows: newRows }),
    // removedFacets is a list of facets filters (only asset_type for now) that the user has removed.
    // This is used in Basic Search and Facet Choice on asset landing pages where the filter for that type
    // is automatically added by React. Need to remember if the user removes it and not add it on subsequent searches.
    removedFacets: [],
    facetAdded: (fctid) => {
        let rmFacetList = get().removedFacets;
        if (rmFacetList.includes(fctid)) {
            rmFacetList = rmFacetList.splice(rmFacetList.indexOf(fctid), 1);
            set({ removedFacets: rmFacetList });
        }
    },
    facetRemoved: (fctid) => {
        let rmFacetList = get().removedFacets;
        if (!rmFacetList.includes(fctid)) {
            rmFacetList.push(fctid);
            set({ removedFacets: rmFacetList });
        }
    },
    // Returns true if the user has removed this facet
    isRemoved: (facet_field, facet_val) => {
        const rmfilters = get().removedFacets;
        const filter_id = `${facet_field}:${facet_val}`;
        return rmfilters.includes(filter_id);
    },
}));
