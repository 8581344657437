import React from 'react';
import ReactDOM from 'react-dom';
import { openTabStore } from '../../hooks/useCloseStore';
import { browseSearchToggle } from '../../hooks/useBrowseSearchToggle';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { GiHamburgerMenu, ImTree, MdLogin } from 'react-icons/all';
import './MainSearchToggle.scss';
import { LoginLink } from '../LoginLink';
import { MandalaSettings } from '../MandalaSettings/MandalaSettings';
import $ from 'jquery';
// import {Hamburger} from "../MainNavToggle/Hamburger";

const target = document.getElementById('browseSearchPortal');

export function AdvancedToggle() {
    //const [mode, setMode] = useState(viewMode || 'off'); // "search" | "tree" | "off"

    // Get function to change state of the rightsidebar (open/close)
    const setSearch = browseSearchToggle((state) => state.setSearch);
    const setBrowse = browseSearchToggle((state) => state.setBrowse);
    const setOpenTab = openTabStore((state) => state.changeButtonState);
    const openTab = openTabStore((state) => state.openTab);
    const default_value =
        openTab == 1 ? 'search' : openTab == 2 ? 'browse' : '';
    const isMenuSidebar = process?.env?.REACT_APP_DISPLAY_MENU_TOGGLE;

    // Set open button to the browse tree for kmaps
    React.useEffect(() => {
        const pthpts =
            process.env.REACT_APP_STANDALONE === 'standalone'
                ? window.location.hash.split('/')
                : window.location.pathname.split('/');
        if (
            (pthpts?.length > 1 &&
                ['places', 'subjects', 'terms'].includes(pthpts[1])) ||
            default_value == 'browse'
        ) {
            setOpenTab(2);
        } else if (!isNaN(window?.mandala_wp?.sidebar_state)) {
            const otval = window.mandala_wp.sidebar_state * 1;
            setOpenTab(otval);
        }
    }, []);

    React.useEffect(() => {
        // When sidebar is closed by toggle button, cursor is over button and so it gets focus. Wait and remove that focus.
        if (openTab === 0) {
            setTimeout(() => {
                let tbs = document?.getElementsByClassName(
                    'c-MainSearchToggle--button'
                );
                for (let tct = 0; tct < tbs?.length; tct++) {
                    tbs[tct].classList.remove('focus');
                }
            }, 10);
        } else {
            let id = '#sui-search-filters';
            if (openTab === 2) {
                id = '#sui-search-trees';
            }
            if (openTab === 3) {
                id = '#sui-search-menu';
            }
            setTimeout(function () {
                $(id).focus();
            }, 300);
        }
    }, [openTab]);
    /*
    const settingsButton =
        process.env.REACT_APP_STANDALONE === 'standalone' ? (
            <MandalaSettings />
        ) : null;

 */

    const toggleBtnGroup = (
        <>
            <LoginLink />
            <ToggleButtonGroup
                name="Georgie"
                value={default_value}
                type={'radio'}
                className={'c-MainSearchToggle--group'}
            >
                <ToggleButton
                    name={'viewMode'}
                    value={'search'}
                    type={'radio'}
                    id={'advanced-search-tree-toggle'}
                    className={'c-MainSearchToggle--button advanced'}
                    onMouseUp={(evt) => {
                        if (openTab === 1) {
                            setOpenTab(0);
                        } else {
                            setOpenTab(1);
                            setSearch();
                        }
                    }}
                >
                    <span className={'icon shanticon-preview'}></span>
                </ToggleButton>

                <ToggleButton
                    name={'viewMode'}
                    value={'browse'}
                    type={'radio'}
                    id={'main-search-tree-toggle'}
                    className={'c-MainSearchToggle--button tree'}
                    onMouseUp={(evt) => {
                        if (openTab === 2) {
                            setOpenTab(0);
                        } else {
                            setOpenTab(2);
                            setBrowse();
                        }
                    }}
                >
                    <ImTree></ImTree>
                </ToggleButton>
                {isMenuSidebar === 'true' && (
                    <ToggleButton
                        name={'viewMode'}
                        value={'menu'}
                        type={'radio'}
                        id={'main-menu-toggle'}
                        className={'c-MainSearchToggle--button menu'}
                        onMouseUp={(evt) => {
                            if (openTab === 3) {
                                setOpenTab(0);
                                setTimeout(function () {
                                    $('#main-menu-toggle').removeClass(
                                        'active'
                                    );
                                }, 200);
                            } else {
                                setOpenTab(3);
                                setTimeout(function () {
                                    $('#main-menu-toggle').addClass('active');
                                }, 200);
                            }
                        }}
                    >
                        <GiHamburgerMenu></GiHamburgerMenu>
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
            <MandalaSettings />
        </>
    );

    if (target) {
        return ReactDOM.createPortal(toggleBtnGroup, target);
    } else {
        return toggleBtnGroup;
    }
}
